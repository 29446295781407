.contentContainer {
  padding: 0 3%
}

.contentContainer .header {
  display: flex;
  flex: 1;
  padding: 40px 0 0 0;
  margin: 10px 0;
}

.contentContainer .addStudentsBtn {
  border-radius: 10px;
  margin: 0 10px;
}

.contentContainer .addStudentsBtn i {
  font-size: 30px;
}

@media only screen and (max-width : 992px) {
	.contentContainer .addStudentsBtn span {
    display: none;
  }
	.contentContainer .addStudentsBtn i {
    margin: 0;
  }
}

.contentContainer form {
  background-color: #eeeeee;
}

.createStudentDropdown {
  border-bottom: 0;
  border: 1px solid #979797;
  border-radius: 10px;
  padding: 0 5%;
  width: 100%;
  height: 47px;
  margin: 0;
  background-color: white;
}

.createStudentDropdown input.select-dropdown {
  border-bottom: 0;
  margin: 0;
}

.createStudentDropdown input.select-dropdown:focus {
  border-bottom: 0;
}