.title {
  color: black;
  font-size: calc(20px + 0.5vw);
  font-family: Avenir-Light;
}

.subtitle {
  color: black;
  font-size: calc(15px + 0.5vw);
  font-family: Avenir-Light;
}

.smallText {
  color: black;
  font-size: calc(15px + 0.1vw);
  font-family: Avenir-Light;
}

.bold {
  font-family: Avenir-Black;
}