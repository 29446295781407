
.contentCreator .bundleDetailSection {
  background-color: "#eeeeee";
  display: flex;
  flex: 1;
  margin-top: 10px;
}

.contentCreator .bundleDetailSection .sideSection {
  display: flex;
  flex: 1;
  align-items: center;
}

.contentCreator .bundleDetailSection .sideSection .title {
  color: black;
  font-size: 15px;
  font-family: Avenir-Light;
}

.contentCreator .bundleDetailSection .middleSection {
  display: flex;
  width: 22%;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width : 992px) {
	.contentCreator .bundleDetailSection .sideSection {
    justify-content: center;
  }
  
  .contentCreator .bundleDetailSection .middleSection {
    flex: 1.5
  }
}

.dropdown {
  border-bottom: 0;
  border: 1px solid #979797;
  border-radius: 10px;
  padding: 0 5%;
  width: 100%;
  height: 47px;
  margin: 0;
  background-color: white;
}

.dropdown input.select-dropdown {
  border-bottom: 0;
  margin: 0;
}

.dropdown input.select-dropdown:focus {
  border-bottom: 0;
}