@font-face {
  font-family: Avenir-Light;
  src: local('Avenir-Light'), url(../assets/fonts/Avenir-Light.otf) format('opentype');
}

@font-face {
  font-family: Avenir-Black;
  src: local('Avenir-Black'), url(../assets/fonts/Avenir-Black.otf) format('opentype');
}

body {
  margin: 0;
  font-family: Avenir-Light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  
}

html, form, main, .App {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  padding-left: 300px;
}

@media only screen and (max-width : 992px) {
	.App {
		padding-left: 0;
  }

  .centerAlignOnSmall {
    text-align: center !important;
  }
}

.myHeight {
  height: 100%;
}

.myWidth {
  height: 100%;
}

.add-height {
  height: 100%;
}

/* This is needed in order for a select vlaidation error to work */
select[required]:focus{
  outline: none !important;
}

select[required]{
  display: block;
  padding: 0;
  position: absolute;
  background: transparent;
  color: transparent;
  border: none;
  top: 0;
}

.rtl {
  direction: rtl;
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

/* background colors */
.dynamoBackground {
  background-color: #f8684b;
}

.dynamoBackground:hover {
  background-color: #f8684b;
}

.searchBoxContainer {
  background-color: #eeeeee;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  height: 50px;
}

.searchBoxContainer input {
  border: 0;
  height: 50px !important;
  border-bottom: none !important;
}

.searchBoxContainer input:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}

.dynaInputContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 20px
}

.dynaInputContainer .input-field {
  flex: 1;
  margin: 0;
}

.dynaInputContainer .titleContainer {
  flex: 1
}

.dynaInputContainer .placeholder {
  flex: 1
}

@media only screen and (max-width : 992px) {
	.dynaInputContainer .input-field {
    flex: 2
  }

	.dynaInputContainer .placeholder {
    display: none;
  }
}

.flex-container {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flexListItem {
  display: flex;
  height: 146px;
  margin: 0 0 8px 0;
}

.flexListItem .section {
  flex: 1;
  align-self: stretch;
  padding: 0 1%;
}

.flexListItem .section.center {
  justify-content: center;
}

.flexListItem .section.small {
  flex: 0.5
}

.flexListItem .section.large {
  flex: 2
}